import HeaderAccount from './HeaderAccount.svelte'

const mount	= document.getElementById('hh-mfe-header-account')
const lang	= document.querySelector('html').lang || 'en'

const urlConfig = document.querySelector('html').dataset || {};

if (mount) {
	mount.innerHTML = null
	new HeaderAccount({
		target: mount,
		props: {
			lang: lang,
			urlConfig: urlConfig,
			dataset: mount.dataset
		}
	})
}