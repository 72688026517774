exports.strings = {
    "login": "Log In",
    "account": "Account",
    "myAccount": "My Account",

    "myAccountAlt": "My Account Icon",
    "loginAlt": "Log In Icon",

    "paymentInformation": "Payment Information",
    "shippingAddress": "Shipping Address",
    "orderHistory": "Order History",
    "returnHistory": "Return History",
    "accountLoyalty": "Loyalty Account",
    "signOut": "Sign Out",
    "closeBtn": "Close",
}