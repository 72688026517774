exports.urls = {
	"svgBucket": "https://homehardware.sirv.com/hh/svg",
}

exports.getCookie = function(cname) {
	let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for(let i = 0; i <ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
}

// exports.accountlinks = {
//     "en": {
//         "MyAccount": "/myaccount",
//         "Paymentinformation": "/myaccount#paymentmethods",
//         "Shippingaddress": "/myaccount#address",
//         "OrderHistory": "/myaccount#orderhistory",
//         "ReturnHistory":"/myaccount#returnHistory",
//     },
//     "fr": {
//         "MyAccount": "/fr/myaccount",
//         "Paymentinformation": "/fr/myaccount#paymentmethods",
//         "Shippingaddress": "/fr/myaccount#address",
//         "OrderHistory": "/fr/myaccount#orderhistory",
//         "ReturnHistory":"/fr/myaccount#returnHistory",

//     },


// }


