exports.strings = {
    "login": "Connectez-vous",
    "account": "Compte",
    "myAccount": "Mon compte",

    "myAccountAlt": "Icône de compte",
    "loginAlt": "Icône de connexion",

    "paymentInformation": "Informations de Paiement",
    "shippingAddress": "Adresse de Livraison",
    "orderHistory": "Historique des commandes",
    "returnHistory": "Historique des retours",
    "accountLoyalty": "Compte fidélité",
	"signOut": "Déconnexion",
    "closeBtn": "Fermé",
}