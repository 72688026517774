<script>
  import Portal from 'svelte-portal'
  import { urls, getCookie } from './config.js';
  import en from '../strings.en';
  import fr from '../strings.fr';
  export let lang, dataset;
  export let urlConfig;

  const { kiboUrl, decoupledUrl } = urlConfig;

  // export let userData;
  let strings = en;
  if (lang == 'fr') {
    strings = fr;
  }
  let _strings = strings.strings;

  let imgsata = urls.svgBucket;
  let account = false;
  let login = false;
  let panelIsOpen = false
  let trigger;
  let storeSceneEnabled = false;
  //loyalty incognito flags
	let useLoyaltyService = dataset.useLoyaltyService || "false"
	let displayLoyaltyToEndUser = dataset.displayLoyaltyToEndUser || "false"
	let showLoyaltyService = false;

  //Function to check the incognito flags and show loyalty features to end user. Implemented for incognito testing.
	const checkIncognitoFlags = () => {
		if(useLoyaltyService == "true" && displayLoyaltyToEndUser == "true") {
			showLoyaltyService = true;
		}	else if(useLoyaltyService =="true" && displayLoyaltyToEndUser == "false") {
			var url = window.location.search;
			const urlParams = new URLSearchParams(url);
			const showLoyalty = urlParams.get('showLoyalty');
			if(showLoyalty == "true") {
				showLoyaltyService = true;
			}
		}
	}
  checkIncognitoFlags();

  const openPanel = () => {
    panelIsOpen = true;
  }

  const closePanel = () => {
    panelIsOpen = false;
  }


  // const anonymousLogin = async (userId) => {
  // 	try {
  // 		let response = await fetch(`${decoupledUrl}/_data/${lang}/anonymouslogin/${userId}`)
  // 		let json = await response.json()
  // 		console.log('anonymus response',json)
  // 	} catch (error) {
  // 		console.log('anonymus error',error)
  // 	}
  // }
  function main() {
    //Get cookie data for user
    let userCookie = getCookie('user');
    if (userCookie) {
      let userData = JSON.parse(userCookie);
      if (
        userData &&
        (userData.isAnonymous === 'false' || userData.isAnonymous === false)
      ) {
        account = true;
      }
    }
  }

  main();

  async function getStoreSceneAttributeValue(){
    // let  preferredStore = JSON.parse(localStorage.getItem('preferredStore'));
    // let storeCode =  preferredStore.code;
    // let response = await fetch(`/_data/${lang}/store/${storeCode}`)
    // let res = await response.json();
    // let preferredStoreAttr = res?.attributes;

    // if(preferredStoreAttr){
    //   storeSceneEnabled = preferredStoreAttr.find(a => a.fullyQualifiedName == "tenant~store-scene-enabled")?.values[0];
    // }

    storeSceneEnabled = window.preferredStore?.attributes?.['store-scene-enabled'] == true

  }

getStoreSceneAttributeValue();

  // console.log('UserData from MFE', userData);

  // const toggle = () => {
  //   const uiClose = new CustomEvent('uiClose', {
  //     bubbles: false,
  //     detail: 'hh-mfe-header-account'
  //   });
  //   window.dispatchEvent(uiClose);
  //   open = open ? false : true;
  // };

  const signout = () => {
    // window.localStorage.removeItem("userid");
    account = false;
    document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${window.location.hostname.replace('www','')}; path=/;`;
    document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${window.location.hostname.replace('www','')}; path=/`;

    //Remove Wishlist data in localStorage
    window.localStorage.removeItem('wishlistProducts');
    //Remove cartSummary data in localStorage
    window.localStorage.removeItem('hh_cart_summary');

    window.location.href = `${decoupledUrl}/_api/${lang}/user/logout`;
  };

  const getAccount = async () => {
    try {
      // let response = await fetch(`${decoupledUrl}/_data/${lang}/login`, {
      let response = await fetch(`/_data/${lang}/login`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'no-cors'
        // data: JSON.stringify({
        // 	username: "nithin.j@ignitiv.com",
        // 	password: "Matekk@2010",
        // }),
      });
      let json = await response.json();
      // console.log("sata", json);
      if (json.userId) {
        localStorage.setItem('userid', json.userId);
        document.cookie = 'userid=' + json.userId;
        account = true;
      }
    } catch (error) {
      account = false;
      console.log(error);
    }
  };

  // if(!userData && userData.userId !=''){
  // 	anonymousLogin();
  // }

  // if (user === "NO Data") {
  // 	login = true;
  // } else {
  // 	login = false
  // 	console.log("hiiiiiiiiiiiiiii");
  // }
  // Listen for the event.
  // window.addEventListener('storechanged', function (e) {
  // 	alert('hh-mfe-header-account: someone changed the store (?!?!)')
  // }, false);

  // Listen for uiClose event

  // window.addEventListener(
  //   'uiClose',
  //   e => {
  //     if (e.detail != 'hh-mfe-header-account') open = false;
  //   },
  //   false
  // );


//   window.addEventListener('mouseup', function(e){ 
//     let containerAccount = document.getElementById('accdropdown'); 
//      if(containerAccount){
//         if (!containerAccount.contains(e.target)){
//           toggle()
//       }
//     }
// });
  const accountDropdownLinkHandler = event => {
    /**
     * settings-tab
     * payment-methods-tab
     * address-tab
     * orderhistory-tab
     * returnHistory-tab
     */
    let url = new URL(event.target.href);
    // console.dir(url);

    document.querySelector('#account-nav')
      ? document.querySelector('#account-nav').classList.add('open')
      : '';
    document.querySelector(url.hash + '-tab')
      ? document.querySelector(url.hash + '-tab').click()
      : '';
    window.location.href = event.target.href;
    // if(url.origin !== window.location.origin){
    // }
    closePanel();
  };
  window.onload = function accountDropdownupdateBlock() {
    let url2 = window.location.hash;
    if (url2 === '#paymentmethods') {
      document.getElementById('paymentmethods').className += ' active';
      document.getElementById('settings').className =
        ' tab-pane  myaccount-block settings-block';
    } else if (url2 === '#address') {
      document.getElementById('addressbookTab').className += ' active';
      document.getElementById('settings').className =
        ' tab-pane  myaccount-block settings-block';
    } else if (url2 === '#orderhistory') {
      document.getElementById('orderhistory').className += ' active';
      document.getElementById('settings').className =
        ' tab-pane  myaccount-block settings-block';
    } else if (url2 === '#returnHistory') {
      document.getElementById('returnHistory').className += ' active';
      document.getElementById('settings').className =
        ' tab-pane  myaccount-block settings-block';
    } else if (url2 === '#accountLoyalty') {
      document.getElementById('accountLoyalty').className += ' active';
      document.getElementById('settings').className =
        ' tab-pane  myaccount-block settings-block';
    }
  };
  window.addEventListener('hhPanelClose', closePanel, false)
  window.addEventListener('hhSignOut', signout, false)
  window.addEventListener('signupSuccess', main);

  $: document.querySelector('hh-panel').hidden = !panelIsOpen

</script>

<!-- <a><img
			src="/svg/icon.account.svg"
			alt="My Account"
			class="icon"
			width="0"
			height="0"
		/>
		Log In
	</a> -->
{#if account === true}
  <a
    bind:this={trigger}
    href="{kiboUrl}/{lang}/user/login"
    on:click|preventDefault={openPanel}
  >
    <img
      src="{urls.svgBucket}/icon.account.svg"
      alt={_strings.myAccountAlt}
      class="icon"
      width="0"
      height="0"
    />
    {_strings.account}
  </a>
{:else}
  <a href="{kiboUrl}/{lang}/landing/user/login?returnurl={window.location.href}">
    <img
      src="{urls.svgBucket}/icon.account.svg"
      alt={_strings.loginAlt}
      class="icon"
      width="0"
      height="0"
    />
    {_strings.login}
  </a>
{/if}

{#if panelIsOpen}
<Portal target='hh-panel'>
  <header>
    <div class="dc-my-account">
      <img
        src="{urls.svgBucket}/icon-vector.svg"
        alt="My Account"
        height="20px"
        width="20px"
      />
      <a class="h3" id="dc-account-link" href="{kiboUrl}/{lang}/myaccount">{_strings.myAccount}</a>
    </div>
    <button class="Button ButtonGhost" on:click|preventDefault={closePanel}>{_strings.closeBtn}</button>
  </header>
  <hr data-no-padding/>
  <div class="dc-account-links">
    <p>
      <a
        id="dc-account-link"
        href="{kiboUrl}/{lang}/myaccount#paymentmethods"
        on:click|preventDefault={accountDropdownLinkHandler}
        >{_strings.paymentInformation}</a
      >
    </p>
    <p>
      <a
        id="dc-account-link"
        href="{kiboUrl}/{lang}/myaccount#address"
        on:click|preventDefault={accountDropdownLinkHandler}
        >{_strings.shippingAddress}</a
      >
    </p>
    <p>
      <a
        id="dc-account-link"
        href="{kiboUrl}/{lang}/myaccount#orderhistory"
        on:click|preventDefault={accountDropdownLinkHandler}
        >{_strings.orderHistory}</a
      >
    </p>
    <p>
      <a
        id="dc-account-link"
        href="{kiboUrl}/{lang}/myaccount#returnHistory"
        on:click|preventDefault={accountDropdownLinkHandler}
        >{_strings.returnHistory}</a
      >
    </p>
    {#if showLoyaltyService && storeSceneEnabled}
      <p>
        <a
          id="dc-account-link"
          href="{kiboUrl}/{lang}/myaccount#accountLoyalty"
          on:click|preventDefault={accountDropdownLinkHandler}
          >{_strings.accountLoyalty}</a
        >
      </p>
    {/if}
    <p>
      <a
        id="dc-account-link"
        href={`${decoupledUrl}/_api/${lang}/user/logout`}
        on:click|preventDefault={signout}
      >{_strings.signOut}</a
      >
    </p>    
  </div>
</Portal>
{/if}

<style>
  #dc-account-link {
    color: black;
  }
  #dc-account-link:hover {
    text-decoration: 0.25px underline;
  }
  .dc-my-account {
    /* padding: 10px 0px 20px 0px; */
    display: flex;
    margin-left: 1.125em;
  }
  .dc-my-account > a {
    margin-left: calc(var(--gap) / 2);
  }
  .dc-account-links {
    margin-left: 1.125em;
  }
  /* .dc-account-dropdown div:nth-child(2) {
    display: grid;
    border-bottom: 0.25px solid #54585a;
    grid-template-columns: 18% auto;
    align-items: center;
    padding-top: 20px;
    text-align: center;
    justify-content: center;
   } */
  /*.dc-account-dropdown div:nth-child(2) img {
    padding-bottom: 15px;
  } */

  /* .dropdown-close-btn {
    position: absolute;
    top: 8px;
    right: 11px;
    height: 15px;
    width: 15px;
    cursor: pointer;
  } */

  /* @media (max-width: 600px) {
    .dropdown-close-btn {
      padding-left: 124px;
    }
  } */
</style>
